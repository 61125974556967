<button
  mat-raised-button
  *ngIf="logicRef.type === 'form'"
  (click)="editForm()"
  [ngStyle]="{
    color: 'white',
    'background-color': processNode.node.style.backgroundColor
  }"
  [attr.aria-label]="('lawyer.editor.form.title' | translate) + ' - ' + ('aria.label.clickToEdit' | translate)"
  tabindex="0"
>
  {{ "lawyer.editor.form.title" | translate }}
</button>
<ng-container *ngComponentOutlet="activeComponent; injector: injector">
</ng-container>
